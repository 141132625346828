*{
    padding: 0;
    margin: 0;
    text-decoration: none;
   
}
html,body,#root,.app{
    width: 100%;
    height: 100%;
}
.app{
    
    display: flex;
    flex-direction: column;
}
.headers{
    height: 84px;
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    border: 0px dashed red;
}
.headers-left{
    float: left;
    width: 360px;
    padding: 13px 0 0;
    text-align: center;
    line-height: 40px;
}
.headers-ul{
    width: 784px;
    float: right;
}
.headers-li{
    float: left;
    line-height: 84px;
    text-align: center;
    list-style:none;
}
.headers-a{
    display: block;
    padding: 0px 23px;
    font-size: 16px;
    color: #555;
    border: 0px dashed red;
}
.headers-s{
}
.Md{
    position: relative;
    border: 0px solid blue;
}
.Mul{
    display: block;
    list-style-type: disc;
}
.Mli{
    list-style: none outside none;
}
.Ma{
    text-decoration: none;
    color: #000;
    background: transparent;
    outline: none;
}
.Mimg{
    width: 100%;
    height: 500px;
    margin: 0 auto;
    display: block;
    max-width: 1903px;
}
.footer{
    width: 100%;
    background: #5c5c5c !important;
    padding: 50px 0;
    color: #fff !important;
}
.footer-li{
    line-height: 55px;
    margin: 0 auto;
    overflow: hidden;
    border: 0px dashed red;
}
.footer-span{
    width: 100%;
    align-items: center;
    margin: 0 auto;
    margin-left: 25%
}
.mrin{
    width: 1200px;
    margin: 5px auto;
}
.mrin-left{
    float: left;
    width: 1200px;
    border-left: 0px solid #E5E5E5;
    padding-left: 0px;
}
.mrin-h2{
    font-size: 30px;
    font-weight: normal;
    text-align: center;
    padding: 15px 0;
    color: #000;
}
.mrin-ner{
    padding: 5px 0px;
    overflow: hidden;
    zoom: 1;
    border-top: 0;
    border-bottom: 0;
}
.mrin-li{
    padding: 11px 0;
    border-bottom: 1px solid #eaeaea;
    width: 100%;
    overflow: hidden;
}
.mrin-z{
    float: left;
    width: 81px;
    overflow: hidden;
    color: #e7463c;
    height: 90px;
}
.day{
    font-size: 44px;
    line-height: 46px;
    display: block;
    text-align: center;
    padding-top: 10px;
}
.year{
    font-size: 14px;
    line-height: 20px;
    display: block;
    text-align: center;
}
.mrin-y{
    float: left;
    width: 77%;
    float: left;
    margin-left: 30px;
    overflow: hidden;
}
.InfoTitle{
    font-weight: normal;
    font-size: 17px;
    margin: 2px 0;
    padding: 4px 0;
    display: block;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.InfoContent{
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    padding: 0;
    color: #565656;
    width: 100%;
    line-height: 32px;
}
.mrin-body{
    padding: 5px 0px;
    overflow: hidden;
    zoom: 1;
    border-top: 0;
    border-bottom: 0;
}
.mrin-body1{
    line-height: 2.0em;
    font-size: 16px;
}
.content1{
    padding: 5px 0px;
    overflow: hidden;
    zoom: 1;
    border-top: 0;
    border-bottom: 0;
    
}
.content2{
    width: 1003px;
    display: table;
    margin: 0 auto;
    position: relative;
    margin-left: 200px;
}
.content3{
    line-height: 24px;
    font-family: "微软雅黑";
    font-size: 16px;
    text-indent: 0;
    width: 700px;
    padding: 0 0 30px 230px;
    
}
.content4{
    display: flex;
}
.contentp{
    margin-left: 30px;
    margin-right: 20px;
}
.contentin{
    height: 25px;
}
.contentp1{
    margin-left: 36.7px;
    margin-right: 20px;
    width: 60px;
    color: #fff;
    border-color: #1890ff;
    background: #1890ff;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px #0000000b;
}
.ant-modal-mask{
    position:relative !important;
}
.headers1{
    height: 560px !important;
    width: 1218px;
    margin: 0 auto;
    overflow-y:auto;
}
.headers2{
    width:2400px;
    display: flex;
}
.headers11{
    height: 270px;
    width: 1218px;
    margin: 0 auto;
    margin-top: 20px;
}
.oone{
    width: 100%;
}